import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import "../../components/all.sass";
import Spinner from "../../components/Spinner";

// https://amosinstitute-meal-plan-recipes.s3-us-west-2.amazonaws.com/
// ketoflex/antioxidant-trail-mix
// .json

export default class MealPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: false,
      error: null
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const API =
      "https://amosinstitute-meal-plan-recipes.s3-us-west-2.amazonaws.com/";
    const assetName = this.props.location.search.substr(1);
    const QUERY = assetName + ".json";

    if (!assetName) {
      var error = { message: "Not a Valid Resource." };
      this.setState({ error, isLoading: false });
    }

    fetch(API + QUERY)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong...");
        }
      })
      .then(data => {
        this.setState({ data: data, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { data, isLoading, error } = this.state;

    if (error) {
      return (
        <article className="message is-danger" style={{ margin: "50px" }}>
          <div className="message-body">
            <strong>{error.message}</strong>
            <p>
              We apologize for the inconvenience. If this issue persists, please
              send us an email at <u>info@amosinstitute.com</u>.
            </p>
          </div>
        </article>
      );
    }

    if (isLoading || Object.entries(data).length === 0) {
      return (
        <div style={{ minHeight: "800px" }}>
          <Spinner />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Meal Plans | Amos Institute</title>
          <meta name="robots" content="noindex" />
          <meta name="referrer" content="origin" />
        </Helmet>
        <Navbar />
        <section className="section blog-post" style={{ marginTop: "80px" }}>
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h1 className="title is-size-1 has-text-black has-text-weight-bold">
                  {data.title}
                </h1>
                {data.image ? <img src={data.image} alt={data.title} /> : ""}
                <p>{data.topText}</p>
                <p>{data.servingText}</p>
                <h2>{data.ingredientsOneTitle}</h2>
                <ul>
                  {data.ingredientsOneList.map((value, index) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ul>
                {data.ingredientsTwoTitle ? (
                  <h2>{data.ingredientsTwoTitle}</h2>
                ) : (
                  ""
                )}
                <ul>
                  {data.ingredientsTwoList.map((value, index) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ul>
                <h2>Directions</h2>
                <ol>
                  {data.directionsList.map((value, index) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ol>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
